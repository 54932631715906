import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import QuestContext from "../../Shared/QuestContext";

import QuestDetail from "./QuestDetail";
import QuestList from "./QuestList";
import QuestMap from "./QuestMap";
import CreateQuest from "./Giver/CreateQuest";
import SearchFilters from "../../Shared/SearchFilters";
import * as routes from "../../routes/routes";

import { firestore, auth } from "../../Utils/Firebase";

const ComponentMyQuest = () => (
  <QuestContext.Consumer>
    {myQuest =>
      myQuest && myQuest[0].questStatus < 2 ? (
        <Redirect to={"/quests/" + myQuest[1]} />
      ) : (
        <Redirect to={"/quests"} />
      )
    }
  </QuestContext.Consumer>
);

/**
 * Container for the quests page
 */
class Quests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allQuests: [],
      filteredQuests: [],
      markers: []
    };

    this.filterQuests = this.filterQuests.bind(this);
  }

  componentDidMount() {
    // FETCH ALL VALID QUESTS
    let UUID = auth.getUserId();
    firestore
      .onceGetActiveQuests()
      .then(snapshot => {
        let quests = [];
        snapshot.forEach(doc => {
          let quest = doc.data();
          quest.id = doc.id;
          quest.created = quest.created.toDate();
          quest.pinImage =
            "https://firebasestorage.googleapis.com/v0/b/gentle-student.appspot.com/o/Quests%2Fquest_pin.png?alt=media";
          if (UUID !== quest.questGiverId && quest.questStatus < 1)
            quests.push(quest);
        });

        // SETUP MARKER OBJECTS
        let markers = this.setupMarkers(quests);
        // console.log({markers});

        // SET STATE (only once)
        this.setState({ allQuests: quests, filteredQuests: quests, markers });
      })
      .catch(error => {
        console.error({ error }, error);
      });
  }

  /**
   * Create an array of marker objects.
   * The marker object contains a latlng component containing the latitude en longitude of the marker,
   * the url to the pin image and the quest component it relates to
   * @param {Array} quests array of quest objects
   * @returns array of marker objects
   */
  setupMarkers(quests) {
    let markers = [];
    quests.forEach(quest => {
      let latlng = { lat: quest.latitude, lng: quest.longitude };
      let pin = quest.pinImage;
      let marker = {
        latlng,
        pin,
        quest
      };
      markers.push(marker);
    });
    return markers;
  }

  componentDidUpdate(prevProps, prevState) {
    // Update markers
    if (prevState.allQuests !== this.state.allQuests) {
      let markers = this.setupMarkers(this.state.allQuests);
      this.setState({ markers });
    }
  }

  /**
   * Filters the quests based on input value of the search bar
   * @param {Event} event generated by search bar
   */
  filterQuests(event) {
    event.preventDefault();
    let filterInput = event.target.value;

    // Reset filter options if nothing is written in target
    if (filterInput.toString().length === 0) {
      let markers = this.setupMarkers(this.state.allQuests);
      this.setState({ filterQuests: this.state.allQuests, markers });
      return;
    }

    let initialArray = this.state.allQuests;
    let filtered = initialArray.filter(function(quest) {
      let title = quest.title;
      return title.toLowerCase().search(filterInput.toLowerCase()) !== -1;
    });

    // UPDATE MARKERS
    let markers = this.setupMarkers(filtered);

    this.setState({ filteredQuests: filtered, markers });
  }

  render() {
    const { markers, filteredQuests } = this.state;
    return (
      <>
        <Switch>
          <Route path={routes.CreateQuest} render={() => <CreateQuest />} />
          <Route path={routes.MyQuest} render={() => <ComponentMyQuest />} />
          <Route
            path={"/quests/:id"}
            render={({ match }) => <QuestDetail match={match} />}
          />
          <Route
            exact
            path={"/quests"}
            render={() => (
              <div className="content">
                <SearchFilters
                  title="Quests"
                  filterFunction={this.filterQuests}
                />
                <div className="quests-content">
                  <div className="content-right">
                    <QuestMap markers={markers} />
                  </div>
                  <div className="content-left">
                    <QuestList allQuests={filteredQuests} />
                  </div>
                </div>
              </div>
            )}
          />
        </Switch>
      </>
    );
  }
}

export default Quests;
